import React, { useState, useEffect, useContext } from "react";
import styled from "@emotion/styled";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import { Link, useNavigate } from "react-router-dom";
import CreditCardIcon from "@mui/icons-material/CreditCard";

import { orderSuccess, postCheckout } from "../utils/api";
import { MainContext } from "./Main";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { calculatePrice } from "../utils/helpers";
import Tooltip from "@mui/material/Tooltip";

// Product IDs
const MONTHLY_STRIPE_SUB_ID =
  process.env.NODE_ENV === "development"
    ? "price_1Oro0UIwJheCLZQ8s1QetZGQ"
    : "price_1Otcq4IwJheCLZQ8G0MZOBnh";

type productDisplayProps = {
  setSessionId: React.Dispatch<React.SetStateAction<string>>;
  setSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
};
const ProductDisplay = ({ setMessage }: productDisplayProps) => {
  const context = useContext(MainContext);
  const navigate = useNavigate();

  const checkoutClick = (event: React.MouseEvent<HTMLElement>) => {
    onCheckout("subscribe");
  };

  const onCheckout = async (checkOutType: string) => {
    // Stripe checkout flow
    let res = null;
    // The below price_ids come from the Stripe dashboard
    if (checkOutType === "subscribe") {
      res = await postCheckout(MONTHLY_STRIPE_SUB_ID, "ASANA", 0);
    }

    if (res?.ok) {
      const resData = await res.json();
      if (resData === "User already a subscriber") {
        setMessage(
          "You are already a subscriber. Please refresh your browser or send us a message for help.",
        );
      } else if (resData?.url) {
        window.location.replace(resData.url);
      }
    } else {
      setMessage(
        "Something went wrong. Please try again or send us a message for help.",
      );
    }
  };

  const onLoginClick = () => {
    navigate("/login");
  };

  const UserInfoBox = () => {
    if (context?.user?.isAsanaUser) {
      return (
        <Card className="pricing-card">
          <Typography variant="body1" gutterBottom className="top-gap">
            Days until your free trial expires:{" "}
            <span className="emphasize-text">
              {context.user?.asanaFreeTrialDaysRemaining > 0
                ? context.user?.asanaFreeTrialDaysRemaining
                : "Free trial expired"}
            </span>
          </Typography>
          <Typography variant="body1" gutterBottom>
            <div style={{ display: "flex", alignItems: "center" }}>
              Your Intercom Inbox seats:
              <span
                className="emphasize-text"
                style={{ marginLeft: "0.25rem" }}
              >
                {context.user?.intercomAdminSeats}
              </span>
              <Tooltip title="The number of Inbox seats you have active. If this looks incorrect, please contact us">
                <HelpOutlineIcon className="help-icon" fontSize="small" />
              </Tooltip>
            </div>
          </Typography>
          <Typography variant="body1" gutterBottom>
            Your price per month:{" "}
            <span className="emphasize-text">
              ${calculatePrice(context.user?.intercomAdminSeats)}
            </span>{" "}
            {context?.user?.intercomAdminSeats > 0 && (
              <span>
                ({"$"}
                {(
                  calculatePrice(context.user?.intercomAdminSeats) /
                  context.user?.intercomAdminSeats
                ).toFixed(2)}{" "}
                per seat)
              </span>
            )}
          </Typography>

          {context?.user?.asanaMembershipActive ? (
            <Button className="login-button" variant="text">
              Subscribed!
            </Button>
          ) : !context?.user?.email ? (
            <Button
              className="login-button"
              variant="contained"
              onClick={onLoginClick}
            >
              Log in to subscribe
            </Button>
          ) : (
            calculatePrice(context.user?.intercomAdminSeats) > 0 && (
              <>
                <Button
                  className="login-button"
                  id="subscribe"
                  variant="contained"
                  onClick={checkoutClick}
                >
                  <CreditCardIcon sx={{ marginRight: "8px" }} />
                  Subscribe
                </Button>
              </>
            )
          )}
        </Card>
      );
    } else {
      return null;
    }
  };

  return (
    <Styled>
      <div className="product">
        <div className="description">
          <Typography variant="h1" gutterBottom>
            Asana in the Inbox Pricing
          </Typography>
        </div>

        <div className="pricing-cards-container">
          <UserInfoBox />

          <Card className="pricing-card">
            <Typography variant="body1" gutterBottom>
              Our pricing is based on the number of Intercom Inbox seats you
              have. The price per user decreases, the more seats you have.
            </Typography>
            <Typography variant="body1" gutterBottom>
              We also offer a generous free tier for small teams.
            </Typography>
            <Typography variant="body1" gutterBottom className="top-gap">
              All plans include:
            </Typography>
            <ul>
              <Typography variant="body1" gutterBottom>
                <li>30-day free trial</li>
                <li>Unlimited task creation</li>
                <li>Unlimited task linking</li>
                <li>Asana to Intercom real-time task syncing</li>
                <li>Responsive support</li>
                <li>Cancel anytime</li>
              </Typography>
            </ul>

            <TableContainer component={Paper}>
              <Table className="table-container" aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Intercom Inbox seats</TableCell>
                    <TableCell align="right">Price / month</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>1-5</TableCell>
                    <TableCell align="right">FREE</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>6-10</TableCell>
                    <TableCell align="right">$5 per user</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>11-20</TableCell>
                    <TableCell align="right">$4 per user</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>21-50</TableCell>
                    <TableCell align="right">$3 per user</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>51+</TableCell>
                    <TableCell align="right">$2 per user</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </div>
      </div>
    </Styled>
  );
};

const SuccessDisplay = () => {
  return (
    <Styled>
      <div className="product Box-root">
        <div className="description Box-root">
          <h3>Subscription successful!</h3>
          <Typography variant="body1">
            Thank you! Your subscription was successful and we have sent a
            confirmation email to you.
          </Typography>
          <Typography variant="body1">
            You can manage your subscription any time by clicking the user
            avatar in the top right.
          </Typography>
          <div className="success-continue-btn">
            <Link to={"/"}>
              <Button variant="contained">Continue</Button>
            </Link>
          </div>
        </div>
      </div>
    </Styled>
  );
};

type messagePropsType = {
  message: string;
};
const Message = ({ message }: messagePropsType) => (
  <section>
    <Typography variant="body1">{message}</Typography>
  </section>
);

export default function PricingAsana() {
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [sessionId, setSessionId] = useState("");
  const context = useContext(MainContext);

  useEffect(() => {
    const onSuccess = async (newSessionId: string) => {
      // We don't need to provide a referral code for Stripe here because we provide it in the checkout session
      await orderSuccess(newSessionId, context.user.id);
      await context.refreshUser();
    };

    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      const newSessionId = query.get("session_id") || "";
      if (!success) {
        onSuccess(newSessionId);
        setSuccess(true);
      }
      setSessionId(newSessionId);
    }

    if (query.get("canceled")) {
      setSuccess(false);
      setMessage("Order canceled.");
    }

    if (query.get("limit_reached")) {
      setSuccess(false);
      setMessage(
        "Free alert limit reached. Consider our paid plans to create more.",
      );
    }
  }, [sessionId, success, context]);

  if (!success && message === "") {
    return (
      <ProductDisplay
        setSuccess={setSuccess}
        setSessionId={setSessionId}
        setMessage={setMessage}
      />
    );
  } else if (success && sessionId !== "") {
    return <SuccessDisplay />;
  } else {
    return (
      <>
        <Message message={message} />
        <ProductDisplay
          setSuccess={setSuccess}
          setSessionId={setSessionId}
          setMessage={setMessage}
        />
      </>
    );
  }
}

const Styled = styled.div`
  display: flex;
  flex-direction: column;

  .bold {
    font-weight: bold;
  }

  .success-continue-btn {
    margin-top: 20px;
  }

  .pricing-cards-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 2rem 0 1rem 0;
    gap: 2rem;

    .pricing-card {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      max-width: 700px;
      min-height: 200px;
      padding: 1rem;

      .login-button {
        margin: 2rem 0 0.5rem 0;
        width: 220px;
      }
    }
  }

  .questions-btn {
    margin-top: 2rem;
  }

  .top-gap {
    margin-top: 3rem;
  }

  .table-container {
    max-width: 300px;
  }

  .emphasize-text {
    font-weight: bold;
  }

  .help-icon {
    margin-left: 0.3rem;
  }
`;
