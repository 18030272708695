import React from "react";
import Typography from "@mui/material/Typography";
import styled from "@emotion/styled";

import Product from "./Product";
import { Link } from "react-router-dom";

const ProfanityFilter = () => {
  const productName = "Profanity Filter";
  const productLogoUrl =
    "https://d2sztxeooqpvky.cloudfront.net/profanity_filter_logo_512x512.png";

  const Content = () => {
    return (
      <Styled>
        <Typography variant="h3" gutterBottom id="top-of-page">
          Contents
        </Typography>
        <ul>
          <Typography variant="body1">
            <li>
              <a href="#installation">Installation</a>
            </li>
            <li>
              <a href="#usage">Usage</a>
            </li>
            <li>
              <a href="#pricing">Pricing</a>
            </li>
            <li id="installation">
              <a href="#faq">FAQ</a>
            </li>
          </Typography>
        </ul>
        <Typography variant="h3" gutterBottom>
          Installation
        </Typography>
        <Typography variant="body1" gutterBottom>
          1. Visit the{" "}
          <a
            href="https://app.intercom.com/a/apps/_/appstore?app_package_code=profanity-filter"
            target="_blank"
            rel="noopener noreferrer"
          >
            Intercom app store
          </a>{" "}
          and install the Profanity Filter app
        </Typography>
        <Typography variant="body1" gutterBottom>
          2. You will be asked to authorize the app’s access to your Intercom
          account. We only ask for permissions strictly needed for app
          functionality.
        </Typography>
        <Typography variant="body1" gutterBottom>
          3. You’re done 🎉
        </Typography>
        <Typography variant="body1" gutterBottom>
          If you have any issues with the above, please contact us at:{" "}
          <a href="mailto:support@thackd.com">support@thackd.com</a>
        </Typography>
        <Typography variant="body1" gutterBottom id="usage">
          <a href="#top-of-page">Back to top</a>
        </Typography>

        <Typography variant="h3" gutterBottom>
          Usage
        </Typography>
        <Typography variant="body1">
          Once installed Profanity Filter will automatically start scanning your
          incoming conversations for offensive and abusive language.
        </Typography>
        <Typography variant="body1" gutterBottom>
          If the app detects it, it will delete the offending message and create
          a new message with the offensive words replaced by four asterisks:
          ****
        </Typography>
        <Typography variant="body1" gutterBottom>
          What your customers see:
        </Typography>
        <img
          src="https://d2sztxeooqpvky.cloudfront.net/profanity-filter/what-customers-see.webp"
          alt="What customers see"
          className="image"
        />
        <Typography variant="body1" gutterBottom>
          What your agents see:
        </Typography>
        <img
          src="https://d2sztxeooqpvky.cloudfront.net/profanity-filter/what-agents-see.webp"
          alt="What agents see"
          className="image"
        />
        <Typography variant="body1" gutterBottom id="pricing">
          <a href="#top-of-page">Back to top</a>
        </Typography>

        <Typography variant="h3" gutterBottom>
          Pricing
        </Typography>
        <Typography variant="body1" gutterBottom>
          You can see our pricing on our{" "}
          <Link to="/profanity-pricing">Pricing page</Link>
        </Typography>
        <Typography variant="body1" gutterBottom id="faq">
          <a href="#top-of-page">Back to top</a>
        </Typography>

        <Typography variant="h3" gutterBottom>
          FAQ
        </Typography>
        <Typography variant="h4" gutterBottom>
          How does Profanity Filter work?
        </Typography>
        <Typography variant="body1" gutterBottom>
          Each time one of your customers sends you a message via Intercom,
          Intercom notifies us and our servers check the content of the message
          for words that are in our list of offensive words or close spellings.
        </Typography>
        <Typography variant="body1" gutterBottom>
          If a match is found, we analyze the text for toxic intent using a
          world-leading machine learning model. This helps avoid censoring text
          where no ill-will was intended or due to spelling errors.
        </Typography>
        <Typography variant="body1" gutterBottom>
          If it matches the threshold to be deemed possibly toxic, we save the
          original content, notify Intercom to delete the offending message and
          create a new message with the offensive words censored.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Finally, we send a message to the customer from Intercom’s Operator
          letting them know what has happened.
        </Typography>
        <Typography variant="body1" gutterBottom>
          <a href="#top-of-page">Back to top</a>
        </Typography>

        <Typography variant="h4" gutterBottom>
          Do you have to display “This message was deleted”?
        </Typography>
        <Typography variant="body1" gutterBottom>
          Unfortunately we are limited by Intercom. Intercom does not allow a
          message content to be changed so we have to delete it and create a new
          one with the offensive words censored. We then notify the customer
          what has happened to avoid confusion.
        </Typography>
        <Typography variant="body1" gutterBottom id="faq">
          <a href="#top-of-page">Back to top</a>
        </Typography>

        <Typography variant="h4" gutterBottom>
          You did not censor a word I want censored
        </Typography>
        <Typography variant="body1" gutterBottom>
          Let us know what the word is and we will see if we can add it to the
          list. In future we’d like to enable each of our customer’s to have a
          custom list of words. Let us know if this is a feature you would be
          interested in.
        </Typography>
        <Typography variant="body1" gutterBottom id="faq">
          <a href="#top-of-page">Back to top</a>
        </Typography>

        <Typography variant="h4" gutterBottom>
          I want to change how the app works or request a new feature
        </Typography>
        <Typography variant="body1" gutterBottom>
          Contact us at <a href="mailto:hello@thackd.com">hello@thackd.com</a>.
          We love to hear feedback and requests.
        </Typography>
        <Typography variant="body1" gutterBottom id="faq">
          <a href="#top-of-page">Back to top</a>
        </Typography>
      </Styled>
    );
  };

  return (
    <Product
      productName={productName}
      productLogoUrl={productLogoUrl}
      Content={Content}
    />
  );
};

const Styled = styled.div`
  max-width: 800px;

  ul ul {
    padding-left: 20px;
  }

  ul p {
    margin-bottom: 0;
  }

  p {
    margin-bottom: 1.5rem;
  }

  h2 {
    margin-bottom: 2rem;
  }

  h3 {
    margin-top: 4rem;
    margin-bottom: 2rem;
  }

  h4 {
    margin-top: 4rem;
    margin-bottom: 2rem;
  }

  .border-left {
    border-left: 1px solid #000;
    padding-left: 1rem;
  }

  .image {
    max-width: 600px;
    @media screen and (max-width: 600px) {
      max-width: 100%;
    }
    margin-bottom: 2rem;
  }
`;

export default ProfanityFilter;
