import React from "react";
import Typography from "@mui/material/Typography";
import styled from "@emotion/styled";

import Product from "./Product";
import { Link } from "react-router-dom";

const AsanaInTheInbox = () => {
  const productName = "Asana in the Inbox";
  const productLogoUrl = "https://d2sztxeooqpvky.cloudfront.net/asana.png";

  const Content = () => {
    return (
      <Styled>
        <Typography variant="h3" gutterBottom id="top-of-page">
          Contents
        </Typography>
        <ul>
          <Typography variant="body1">
            <li>
              <a href="#installation">Installation</a>
            </li>
            <li>
              <a href="#usage">Usage</a>
            </li>
          </Typography>
          <ul>
            <Typography variant="body1">
              <li>
                <a href="#create-asana-task">Create a new Asana task</a>
              </li>
              <li>
                <a href="#link-asana-task"> Link an existing Asana task</a>
              </li>
              <li>
                <a href="#unlink-asana-task">Unlinking a task</a>
              </li>
              <li>
                <a href="#comment-on-asana-task">Commenting on a task</a>
              </li>
              <li>
                <a href="#select-attributes">
                  Select attributes to send to Asana
                </a>
              </li>
              <li>
                <a href="#changing-asana-user">Changing Asana user</a>
              </li>
              <li id="installation">
                <a href="#changing-intercom-user">Changing Intercom user</a>
              </li>
            </Typography>
          </ul>
          <Typography variant="body1">
            <li>
              <a href="#pricing">Pricing</a>
            </li>
          </Typography>
        </ul>
        <Typography variant="h3" gutterBottom>
          Installation
        </Typography>
        <Typography variant="body1" gutterBottom>
          1. Visit the{" "}
          <a
            href="https://app.intercom.com/a/apps/_/appstore?app_package_code=asana-d0aa"
            target="_blank"
            rel="noopener noreferrer"
          >
            Intercom app store
          </a>{" "}
          and install the Asana in the Inbox app
        </Typography>
        <Typography variant="body1" gutterBottom>
          As part of the install flow you will be asked to authorize the app to
          access your Intercom and Asana accounts. We only ask for permissions
          strictly needed for the app functionality.{" "}
        </Typography>
        <div className="border-left">
          <Typography variant="body1" gutterBottom>
            ℹ️ We recommend creating a dedicated Asana user and Intercom user
            for the app and install it using this user. This will give various
            benefits, including:
          </Typography>
          <ul>
            <Typography variant="body1">
              <li>
                Asana tasks created via the app will have a generic author.
              </li>
              <li>You can give the appropriate permissions to these users.</li>
              <li>
                Notes posted in Intercom will have a generic author and will
                appear as new notes for you
              </li>
            </Typography>
          </ul>
        </div>
        <Typography variant="body1" gutterBottom>
          2. First you will be asked to authorize the app’s access to your
          Intercom account:
        </Typography>
        <img
          src="https://d2sztxeooqpvky.cloudfront.net/asana/asana-intercom-permissions.png"
          alt="Intercom authorization"
          className="image"
        />
        <Typography variant="body1" gutterBottom>
          3. After clicking “Authorize” you will be redirected to Asana and
          asked to grant permission for “Asana in the Inbox (thackd.com)” to
          access your Asana account:
        </Typography>
        <img
          src="https://d2sztxeooqpvky.cloudfront.net/asana/asana_permissions.png"
          alt="Asana authorization"
          className="image"
        />
        <Typography variant="body1" gutterBottom>
          4. After clicking “Allow” you will be redirected to the Intercom App
          Store and shown instructions on how to use Asana in the Inbox.
        </Typography>
        <Typography variant="body1" gutterBottom>
          5. Go back to your Intercom Inbox and while looking at a conversation,
          add Asana in the Inbox to the sidebar by clicking “Edit apps” and
          selecting the pin next to the app’s name:
        </Typography>
        <img
          src="https://d2sztxeooqpvky.cloudfront.net/asana/add-asana-to-sidebar.png"
          alt="Add Asana to Inbox sidebar"
          className="image"
        />
        <Typography variant="body1" gutterBottom>
          6. Expand Asana in the Inbox in the sidebar and you should now be able
          to create and link Asana tasks right from the sidebar:
        </Typography>
        <img
          src="https://d2sztxeooqpvky.cloudfront.net/asana/asana-home-empty.png"
          alt="Asana in the sidebar"
          className="image"
        />
        <Typography variant="body1" gutterBottom>
          If you have any issues with the above, please contact us at:{" "}
          <a href="mailto:support@thackd.com">support@thackd.com</a>
        </Typography>
        <Typography variant="body1" gutterBottom id="usage">
          <a href="#top-of-page">Back to top</a>
        </Typography>

        <Typography variant="h3" gutterBottom>
          Usage
        </Typography>

        <Typography variant="body1">
          The app gives you 2 main options when viewing a conversation in
          Intercom:
        </Typography>
        <ol>
          <Typography variant="body1">
            <li>
              <a href="#create-asana-task">Create a new Asana task</a>
            </li>
            <li>
              <a href="#link-asana-task"> Link an existing Asana task</a>
            </li>
          </Typography>
        </ol>
        <Typography variant="body1">
          You can also <a href="#unlink-asana-task">unlink tasks</a> and{" "}
          <a href="#comment-on-asana-task">comment on tasks</a> that you have
          created or linked from the conversation.
        </Typography>
        <Typography variant="body1" gutterBottom id="create-asana-task">
          <a href="#top-of-page">Back to top</a>
        </Typography>

        <Typography variant="h4" gutterBottom>
          1. Create a new Asana task
        </Typography>
        <Typography variant="body1" gutterBottom>
          This will create a new task on the specified project in Asana and link
          the Intercom conversation and task. It will also post a link to the
          task in a note on the Intercom conversation and a link to the Intercom
          conversation on the Asana task.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Additionally, any notes posted on the Asana task or changes in its
          status will be posted as notes on the Intercom conversation.
        </Typography>

        <Typography variant="body1" gutterBottom>
          1. Select “Create a new task”:
        </Typography>
        <img
          src="https://d2sztxeooqpvky.cloudfront.net/asana/asana-home-empty.png"
          alt="Asana in the sidebar"
          className="image"
        />
        <Typography variant="body1" gutterBottom>
          2. Search for the Asana project you wish to create the new task in by
          entering the full or partial project name into the text box and
          clicking the button:
        </Typography>
        <img
          src="https://d2sztxeooqpvky.cloudfront.net/asana/asana-search-project.png"
          alt="Search project"
          className="image"
        />
        <Typography variant="body1" gutterBottom>
          3. Select the project from the list of results:
        </Typography>
        <img
          src="https://d2sztxeooqpvky.cloudfront.net/asana/asana-select-project.png"
          alt="Select project"
          className="image"
        />
        <Typography variant="body1" gutterBottom>
          4. Specify a name and description for your task and, optionally,
          assign the task to a user in the same Asana workspace as the project
          you have selected and assign a due date. Finally, click “Create Asana
          task”:
        </Typography>
        <img
          src="https://d2sztxeooqpvky.cloudfront.net/asana/asana-create-task.png"
          alt="Create task view"
          className="image"
        />
        <Typography variant="body1" gutterBottom>
          5. A task will be created in the linked Asana account and a note is
          added to the Intercom conversation with a link to the task. The note
          is not visible to the customer.
        </Typography>
        <Typography variant="body1" gutterBottom>
          The next time you visit the conversation you will also see a link to
          the task in the sidebar under “Linked tasks”:
        </Typography>
        <img
          src="https://d2sztxeooqpvky.cloudfront.net/asana/asana-home-linked.png"
          alt="Linked project view"
          className="image"
        />
        <Typography variant="body1" gutterBottom>
          In the task description in Asana will be the description you entered
          plus the user who created it, information about the user and company
          associated with the conversation the task was created from and the
          conversation attributes:
        </Typography>
        <img
          src="https://d2sztxeooqpvky.cloudfront.net/asana/asana-task-view.png"
          alt="Linked project view"
          className="image"
        />
        <Typography variant="body1" gutterBottom id="link-asana-task">
          <a href="#top-of-page">Back to top</a>
        </Typography>

        <Typography variant="h4" gutterBottom>
          2. Link an existing Asana task
        </Typography>
        <Typography variant="body1" gutterBottom>
          This will link the Intercom conversation you are looking at to an
          existing Asana task, post a link to the task in a note on the Intercom
          conversation and a link to, and details of, the Intercom conversation
          on the Asana task.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Additionally, any notes posted on the Asana task or changes in its
          status will be posted as notes on the Intercom conversation. You will
          also be able to post comments on the Asana task from the Intercom
          conversation.
        </Typography>
        <Typography variant="body1" gutterBottom>
          1. Select “Link an existing task”:
        </Typography>
        <img
          src="https://d2sztxeooqpvky.cloudfront.net/asana/asana-home-empty.png"
          alt="Linked project view"
          className="image"
        />
        <Typography variant="body1" gutterBottom>
          2. Search for a task by full or partial name e.g. “user creation bug”:
        </Typography>
        <img
          src="https://d2sztxeooqpvky.cloudfront.net/asana/asana-link-task.png"
          alt="Linked project view"
          className="image"
        />
        <Typography variant="body1" gutterBottom>
          and select it from the results:
        </Typography>
        <img
          src="https://d2sztxeooqpvky.cloudfront.net/asana/asana-select-task-to-link.png"
          alt="Linked project view"
          className="image"
        />
        <Typography variant="body1" gutterBottom>
          3. The task and conversation are now linked.
        </Typography>
        <Typography variant="body1" gutterBottom>
          The next time you visit the conversation the task name will be listed
          under “Linked tasks” in the Asana in the Inbox sidebar app.
        </Typography>
        <img
          src="https://d2sztxeooqpvky.cloudfront.net/asana/asana-home-linked-2.png"
          alt="Linked project view"
          className="image"
        />
        <Typography variant="body1" gutterBottom>
          Linked tasks are shown on the conversation in the right panel. You may
          need to refresh the conversation before it appears.
        </Typography>
        <Typography variant="body1" gutterBottom id="unlink-asana-task">
          <a href="#top-of-page">Back to top</a>
        </Typography>

        <Typography variant="h4" gutterBottom>
          Unlinking a task
        </Typography>
        <Typography variant="body1" gutterBottom>
          The links between an Intercom conversation and an Asana task can be
          removed if you no longer want updates on the Asana task to be posted
          to the Intercom conversation.
        </Typography>
        <Typography variant="body1" gutterBottom>
          1. Go to the relevant conversation in the Intercom Inbox
        </Typography>
        <Typography variant="body1" gutterBottom>
          2. In the Asana in the Inbox sidebar app, find the task you want to
          unlink in the “Linked tasks” list and click it
        </Typography>
        <Typography variant="body1" gutterBottom>
          3. Click "Unlink" in the task view:
        </Typography>
        <img
          src="https://d2sztxeooqpvky.cloudfront.net/asana/asana-task-home.png"
          alt="Task home"
          className="image"
        />
        <Typography variant="body1" gutterBottom>
          4. A confirmation screen is shown. You may need to refresh the
          Intercom conversation for the linked task to disappear from the
          “Linked tasks” list.
        </Typography>
        <Typography variant="body1" gutterBottom id="comment-on-asana-task">
          <a href="#top-of-page">Back to top</a>
        </Typography>

        <Typography variant="h4" gutterBottom>
          Commenting on a task
        </Typography>
        <Typography variant="body1" gutterBottom>
          You can post comments on an Asana task from the Intercom conversation.
          This avoids your agents needing to access Asana to reply to questions
          on the Asana task or to add further information to the task.
        </Typography>
        <Typography variant="body1" gutterBottom>
          1. Go to the relevant conversation in the Intercom Inbox
        </Typography>
        <Typography variant="body1" gutterBottom>
          2. In the Asana in the Inbox sidebar app, find the task you want to
          comment on in the “Linked tasks” list and click it
        </Typography>
        <Typography variant="body1" gutterBottom>
          3. Click "Comment" in the task view:
        </Typography>
        <img
          src="https://d2sztxeooqpvky.cloudfront.net/asana/asana-post-comment.png"
          alt="Post comment"
          className="image"
        />
        <Typography variant="body1" gutterBottom>
          4. Enter your comment and click "Post comment"
        </Typography>
        <Typography variant="body1" gutterBottom>
          The comment will be posted to the Asana task including the details of
          the Intercom user who posted it:
        </Typography>
        <img
          src="https://d2sztxeooqpvky.cloudfront.net/asana/asana-comment-added.png"
          alt="Comment added to Asana task"
          className="image"
        />
        <Typography variant="body1" gutterBottom id="select-attributes">
          <a href="#top-of-page">Back to top</a>
        </Typography>

        <Typography variant="h4" gutterBottom>
          Select attributes to send to Asana
        </Typography>

        <Typography variant="body1" gutterBottom>
          This feature is reserved for paid members and those on a free trial.
        </Typography>

        <Typography variant="body1" gutterBottom>
          When you create a task using our app, you have the option to include
          attributes from the Intercom conversation or contact. By default we
          include the name and email of the Intercom admin who created the task,
          but you can also choose to include any Intercom or custom attribute
          from the conversation, contact or company involved in the
          conversation.
        </Typography>

        <Typography variant="body1" gutterBottom>
          To select the attributes you want to include, you first need to
          register an account at thackd.com. You should have received an email
          invitation to register on installation, but please contact us at:
          help@thackd.com if you have not received this.
        </Typography>

        <Typography variant="body1" gutterBottom>
          Once registered, log in to your account at thackd.com and click your
          avatar in the top right corner and select "Task Attribute Editor":
        </Typography>
        <img
          src="https://d2sztxeooqpvky.cloudfront.net/asana/asana-avatar-menu.png"
          alt="View of the avatar menu"
          className="image"
        />

        <Typography variant="body1" gutterBottom>
          This will load a view of all the available attributes you can send to
          Asana. This list includes contact, company and conversation attributes
          and both Intercom standard and your custom attributes. Simply copy the
          attributes you want to include on Asana tasks into the text editor and
          click "Save":
        </Typography>
        <img
          src="https://d2sztxeooqpvky.cloudfront.net/asana/asana-attribute-editor.png"
          alt="View of the Attribute Editor"
          className="image"
        />

        <Typography variant="body1" gutterBottom>
          Once you save your list, the attributes will automatically be included
          in any new Asana tasks you create from Intercom:
        </Typography>
        <img
          src="https://d2sztxeooqpvky.cloudfront.net/asana/asana-custom-attributes.png"
          alt="View of an Asana task with custom attributes"
          className="image"
        />

        <Typography variant="body1" gutterBottom id="changing-asana-user">
          <a href="#top-of-page">Back to top</a>
        </Typography>

        <Typography variant="h4" gutterBottom>
          Changing Asana user
        </Typography>
        <div className="border-left">
          <Typography variant="body1" gutterBottom>
            ℹ️ NOTE: Changing Asana user will unlink all existing conversations
            and tasks. Any Asana tasks created under the previous user will not
            post updates to Intercom unless they are re-linked.
          </Typography>
          <Typography variant="body1" gutterBottom>
            If this is an issue for you, please reach out to us at
            support@thackd.com
          </Typography>
        </div>
        <Typography variant="body1" gutterBottom>
          You may want to change the Asana user the app uses to create tasks to
          use a more generic user or one with different permissions. To do so:
        </Typography>
        <Typography variant="body1" gutterBottom>
          1. Ensure you are logged in to Asana with the user you want to use for
          the app
        </Typography>
        <Typography variant="body1" gutterBottom>
          2. In the Intercom sidebar app, go to the “Settings” menu:
        </Typography>
        <img
          src="https://d2sztxeooqpvky.cloudfront.net/asana/asana-settings-button.png"
          alt="Settings button"
          className="image"
        />
        <Typography variant="body1" gutterBottom>
          3. In Settings, click “Reauthorize Asana access” and follow the
          prompts ensuring the correct Asana user and workspace are selected.
        </Typography>
        <img
          src="https://d2sztxeooqpvky.cloudfront.net/asana/asana-reauthorize-access.png"
          alt="Reauthorize access"
          className="image"
        />
        <Typography variant="body1" gutterBottom id="changing-intercom-user">
          <a href="#top-of-page">Back to top</a>
        </Typography>

        <Typography variant="h4" gutterBottom>
          Changing Intercom user
        </Typography>
        <Typography variant="body1" gutterBottom>
          You may want to change the Asana user the app uses to create tasks to
          use a more generic user, to be notified of new notes on conversations,
          or to use one with different permissions. To do so:
        </Typography>
        <Typography variant="body1" gutterBottom>
          1. While logged in as the Intercom user that installed the app, go to
          the{" "}
          <a
            href="https://app.intercom.com/a/apps/_/appstore?app_package_code=asana-d0aa"
            target="_blank"
            rel="noopener noreferrer"
          >
            Intercom App Store
          </a>
        </Typography>
        <Typography variant="body1" gutterBottom>
          2. Find the Asana in the Inbox App and select it
        </Typography>
        <Typography variant="body1" gutterBottom>
          3. Click “Uninstall”
        </Typography>
        <Typography variant="body1" gutterBottom>
          4. Log in to Intercom as the new user
        </Typography>
        <Typography variant="body1" gutterBottom>
          5. Go back to the Intercom App Store and reinstall Asana in the Inbox
        </Typography>
        <Typography variant="body1" gutterBottom id="pricing">
          <a href="#top-of-page">Back to top</a>
        </Typography>

        <Typography variant="h3" gutterBottom>
          Pricing
        </Typography>
        <Typography variant="body1" gutterBottom>
          Please see our <Link to="/asana-pricing">Pricing page</Link> for
          details.
        </Typography>
        <Typography variant="body1" gutterBottom>
          <a href="#top-of-page">Back to top</a>
        </Typography>
      </Styled>
    );
  };

  return (
    <Product
      productName={productName}
      productLogoUrl={productLogoUrl}
      Content={Content}
    />
  );
};

const Styled = styled.div`
  max-width: 800px;

  ul ul {
    padding-left: 20px;
  }

  ul p {
    margin-bottom: 0;
  }

  p {
    margin-bottom: 1.5rem;
  }

  h2 {
    margin-bottom: 2rem;
  }

  h3 {
    margin-top: 4rem;
    margin-bottom: 2rem;
  }

  h4 {
    margin-top: 4rem;
    margin-bottom: 2rem;
  }

  .border-left {
    border-left: 1px solid #000;
    padding-left: 1rem;
  }

  .image {
    max-width: 600px;
    @media screen and (max-width: 600px) {
      max-width: 100%;
    }
    margin-bottom: 2rem;
  }
`;

export default AsanaInTheInbox;
