import React from "react";
import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";

import ProductContainer from "./ProductContainer";

const Pricing = () => {
  return (
    <Styled>
      <div className="introduction">
        <Typography variant="h1" gutterBottom className="main-title">
          Pricing
        </Typography>
        <Typography variant="h3" gutterBottom className="title">
          Select a product to view pricing
        </Typography>
        <div className="products-container">
          <ProductContainer
            productPageUrl="/asana-pricing"
            logo1Url="https://d2sztxeooqpvky.cloudfront.net/asana.png"
            logo2Url="https://d2sztxeooqpvky.cloudfront.net/Intercom-logo-sq-black-trans.png"
            productTitle="Asana in the Inbox"
            productDescription="From $5 per month"
          />
          <ProductContainer
            productPageUrl="https://monday.com/marketplace/listing/10000525/intercom#pricing"
            logo1Url="https://d2sztxeooqpvky.cloudfront.net/monday_logo.png"
            logo2Url="https://d2sztxeooqpvky.cloudfront.net/Intercom-logo-sq-black-trans.png"
            productTitle="monday.com in the Inbox"
            productDescription="From $10 per month"
          />
          <ProductContainer
            productPageUrl="/profanity-pricing"
            logo1Url="https://d2sztxeooqpvky.cloudfront.net/profanity_filter_logo_512x512.png"
            productTitle="Profanity Filter"
            productDescription="From $10 per month"
          />
          <ProductContainer
            productPageUrl=""
            logo1Url="https://d2sztxeooqpvky.cloudfront.net/explicit-image-blocker-logo.png"
            productTitle="Explicit Image Blocker"
            productDescription="Currently FREE"
          />
        </div>
      </div>
    </Styled>
  );
};

const Styled = styled.div`
  .introduction {
    padding: 1rem 0 0 0;
    text-align: center;
    width: 100%;
  }

  .main-title {
    font-weight: bold;
    text-align: center;
  }

  .title {
    margin-top: 12rem;
    margin-bottom: 4rem;
    text-align: center;
  }

  .products-container {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    flex-wrap: wrap;
    row-gap: 2rem;
    column-gap: 2rem;
  }
`;

export default Pricing;
