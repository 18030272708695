import React, { useContext, useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import { Input } from "@mui/base/Input";
import LoadingButton from "@mui/lab/LoadingButton";
import Snackbar, { SnackbarCloseReason } from "@mui/material/Snackbar";

import { MainContext } from "./Main";
import {
  getAttributesList,
  getAvailableAttributesList,
  postSaveAttributesList,
} from "../utils/api";

const AttributeEditor = () => {
  const context = useContext(MainContext);
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [saving, setSaving] = useState<boolean>(false);
  const [snackOpen, setSnackOpen] = useState<boolean>(false);
  const [snackbarContent, setSnackbarContent] = useState<string>("");
  const [attributesInputValue, setAttributesInputValue] = useState<string>("");
  const [availableAttributesList, setAvailableAttributesList] = useState<
    string[]
  >([]);

  useEffect(() => {
    if (context.user) {
      getAttributeListAsync();
    } else {
      navigate("/login");
    }
  }, [context.user, navigate]);

  const getAttributeListAsync = async () => {
    const attributesListResponse = await getAttributesList();
    if (attributesListResponse.length === 0) {
      setAttributesInputValue("contact Email\ncompany Name\ncontact Browser");
    } else {
      setAttributesInputValue(attributesListResponse.join("\n"));
    }

    const availableAttributesListResponse = await getAvailableAttributesList();
    setAvailableAttributesList(
      availableAttributesListResponse.sort((a: string, b: string) =>
        a.localeCompare(b),
      ),
    );
    setLoading(false);
  };

  const handleCopyToClipboard = useCallback((text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      setSnackbarContent("Copied to clipboard");
      setSnackOpen(true);
    });
  }, []);

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason,
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackOpen(false);
  };

  const handleSaveClick = async () => {
    setSaving(true);
    const attributes = attributesInputValue.split("\n");
    try {
      await postSaveAttributesList(attributes);
      setSnackbarContent("Attributes list saved");
    } catch (e) {
      console.error(e);
      setSnackbarContent("Error saving attributes");
    }
    setSaving(false);
  };

  return (
    <Styled>
      <Typography variant="h1">Task Attribute Editor</Typography>
      <Typography variant="body1" paragraph>
        Here you can edit which attributes from Intercom are added to the task
        description in Asana/monday.
      </Typography>
      <Typography variant="body1" paragraph>
        On the left is a text box where you can enter the attributes you want to
        be included in the task description. It may already be populated with
        the attributes that are currently being used.
      </Typography>
      <Typography variant="body1" paragraph>
        On the right is a list of all attributes available in your Intercom
        workspace.
      </Typography>

      <Typography variant="body1" paragraph>
        Attributes should be written in the format{" "}
        <span className="italic">{'"{Object} {Attribute name}"'}</span>{" "}
        separated by a space, exactly as they appear in the "Available
        Attributes" list e.g.
        <span className="italic">"company Name"</span> or{" "}
        <span className="italic">"contact Email"</span>.
      </Typography>

      <Typography variant="body1" paragraph>
        Each attribute should be on a separate line.
      </Typography>

      <Typography variant="body1" paragraph>
        TIP: You can copy an attribute from the "Available Attributes" list by
        clicking on it.
      </Typography>
      {loading ? (
        <div className="loading-container">
          <CircularProgress />
        </div>
      ) : (
        <div className="content">
          <div className="input-container">
            <Typography variant="h3">Attributes on Tasks</Typography>
            <Input
              multiline
              name="attribute-list"
              value={attributesInputValue}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setAttributesInputValue(event.target.value);
              }}
              rows={20}
              className="attributes-text-box"
            />
            <div className="button-container">
              <LoadingButton
                loading={saving}
                variant="contained"
                onClick={handleSaveClick}
              >
                Save
              </LoadingButton>
            </div>
          </div>
          <div className="available-attributes-container">
            <Typography variant="h3">Available Attributes</Typography>
            {availableAttributesList.map((attribute) => (
              <Typography
                key={attribute}
                variant="body1"
                onClick={() => handleCopyToClipboard(attribute)}
                style={{ cursor: "pointer" }}
              >
                {attribute}
              </Typography>
            ))}
          </div>
        </div>
      )}
      <Snackbar
        open={snackOpen}
        autoHideDuration={3000}
        onClose={handleClose}
        message={snackbarContent}
      />
    </Styled>
  );
};

const Styled = styled.div`
  .MuiTypography-h1 {
    margin-bottom: 1rem;
  }

  .italic {
    font-style: italic;
  }

  .content {
    display: flex;
    align-items: flex-start;
    width: 100%;
    margin-top: 2rem;

    .MuiTypography-h3 {
      margin-bottom: 1rem;
    }

    .MuiTypography-body1 {
      margin-bottom: 0.3rem;
    }

    .input-container {
      .attributes-text-box {
      }

      .base-Input-multiline {
        width: 500px;
        max-width: 100%;
      }
    }

    .available-attributes-container {
      margin-left: 2rem;
    }

    .button-container {
      margin-top: 1rem;
    }
  }

  .loading-container {
    display: flex;
    justify-content: center;
    width: 500px;
    max-width: 100%;
    margin-top: 2rem;
  }
`;

export default AttributeEditor;
